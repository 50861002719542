import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#05508e', //blue old #08256C
      light: '#DFEBEF', //hover color for gradient
      contrastText: "#CAE0E7", // light blue hover color
      contrastTextLight: '#e4f8ff',
      bright: '#35acd4',  // light blue hover color BRIGHT
      brightLight: '#5dc4e6',
      brighterLight: '#c0efff',
      dark: '#022440',
      darkGrey: '#554F51',
      red: '#CF3247',
      green: '#2E7D32', 
      rainbow1: '#FFB3BA',  //  Soft Pink
      rainbow2: '#FFDFBA',  //  Peach
      rainbow3: '#BAE1D8',  //  Pale Mint
      rainbow4: '#E1C6E7',  //  Lavender Mist
      rainbow5: '#A4D8E1',  //  Sky Blue
      rainbow6: '#FFEBA1',  //  Darker Lemon Yellow
      rainbow7: '#fbae89',  //  Coral
      rainbow8: '#b09ac9',  //  purple 
      rainbow9: '#77a1c9',  //  Slate Blue
      rainbow10: '#c0b17c', //  grey yellow

      faqshade1:  '#cbdbe9',  //  primary dark light
      faqshade2:  '#F4FBFC',  //
      faqshade3:  '#d9dee0',  //  darker light grey
      faqshade4:  '#F5F8F9',  //  light grey
      faqshade5:  '#F4FBFC',  //  very light
      faqshade6:  '#c0efff',  //  brighter light

      esopstart:      '#F4FBFC',  //  very light
      esopend:      '#d2ecf1',  //  very light


    },
    secondary: {
      main: '#ba2025',  //red old  #CF3247
      light: '#554F51', //dark grey
      dark: '#0e2a40', //darker grey
      contrastText: '#F5F8F9', //light grey
      contrastTextDarker: '#e3e4e5', //medium grey
    }
  },
    typography: {
      "fontFamily": `"Karla", sans-serif`,
      "fontSize": 18,
      "fontWeightLight": 400,
      "fontWeightRegular": 500,
      "fontWeightMedium": 600
     
  },
});