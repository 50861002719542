import { Box, Stack, Typography, Divider, IconButton, Button, useMediaQuery } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
//import capteclogo from '../images/capteclogo-white.webp'
import capteclogo from '../images/capteclogo-white.webp'
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useRef } from 'react';

export default function Footer({ footerHeight, setFooterHeight }) {
    const theme = useTheme();
    const navigate = useNavigate()
    const privacyClick = () => {
        window.open('/privacypolicy/', '_blank');
    };

    const formsClick = () => {
        navigate('/forms')
    };

    const termsClick = () => {
        navigate('/termsandconditions')
    };

    const handleLinkedinClick = () => {
        window.open('https://www.linkedin.com/company/captec-usa/', '_blank');
    };

    const isXs = useMediaQuery(theme.breakpoints.only('xs'))


    ////////////////////// set fooerHeight
    const footerRef = useRef(null)

    useEffect(() => {
        const updateFooterHeight = () => {
            if (footerRef.current) {
                setFooterHeight(footerRef.current.offsetHeight);
            }
        };

        updateFooterHeight();

        // Optionally update height on window resize
        window.addEventListener('resize', updateFooterHeight);
        return () => window.removeEventListener('resize', updateFooterHeight);
    }, []);

    return (
        <Box //ref={footerRef}
            width='1237.6'
            height='131.8'
            style={{ width: '100%' }}
            sx={{
                position: 'sticky',
                bottom: 0,
                bgcolor: theme.palette.primary.dark,
                marginTop: 'auto',
                padding: 1,
                zIndex: 5,
                overflow: 'hidden'
            }}>
            <Stack                      //most outside layer spaced top to bottom for copyright on bottom with divider
                direction="column"
                //justifyContent="center"
                //alignItems="center"
                spacing={2}
                sx={{ marginLeft: { xs: 1, sm: 2, md: 3 }, marginRight: { xs: 2, sm: 3, md: 4 }, marginTop: 1 }}
                divider={<Divider
                    orientation="horizontal"
                    flexItem
                    sx={{
                        borderColor: 'rgba(255, 255, 255, 0.5)', // Semi-transparent color
                        borderBottomWidth: '0.5px', // Set border bottom width directly
                        borderBottomStyle: 'solid', // Ensure border style is set
                        borderTopWidth: '0px', // Hide top border if not needed
                    }}
                />}
            >

                <Stack                      //outside layer spaced opposite ends of bar
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    sx={{ marginLeft: { xs: 1, sm: 2, md: 3 }, marginRight: { xs: 2, sm: 3, md: 4 } }}
                >

                    <Stack                  //left side of bar, ordered as columns
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={.5}
                    >

                        <Stack              //first column ordered as row for logo next to links 
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={5}
                        /* divider={<Divider orientation="vertical" flexItem
                            sx={{ borderColor: 'white' }} // Set color for Divider
                        />} */
                        >
                            <img src={capteclogo}
                                width="317"
                                height="126"
                                style={{ width: "auto", height: "70px" }}
                                loading='lazy' alt='CapTec Logo'></img>
                            <Stack              //first column ordered as columns 
                                direction="column"
                                sx={{
                                    justifyContent: "space-between",
                                    alignItems: "flex-start",
                                }}
                                spacing={1}
                            /* divider={<Divider orientation="vertical" flexItem
                                sx={{ borderColor: 'white' }} // Set color for Divider
                            />} */
                            >
                                <a
                                    href="/privacypolicy" // Replace this with the actual link
                                    style={{
                                        textDecoration: 'none', // Remove underline
                                        display: 'inline-flex', // Keep inline flex layout
                                        alignItems: 'center', // Align text properly
                                        padding: 0,
                                        minWidth: 'auto', // Avoid unnecessary width
                                    }}
                                >
                                    <Typography
                                        color={'white'}
                                        sx={{ fontSize: { xs: 11, sm: 12, md: 14 } }}
                                        style={{
                                            textTransform: 'capitalize',
                                            transition: 'color 0.3s', // Optional: Add smooth color change on hover
                                        }}
                                    >
                                        Privacy Policy
                                    </Typography>
                                </a>
                                {/* <Button onClick={formsClick}
                                    sx={{
                                        color: 'transparent',
                                        padding: 0,
                                        minWidth: 'auto', // Set minimum width to auto to avoid unnecessary width
                                        '&:hover': {
                                            backgroundColor: 'transparent', // Lighter background color on hover
                                            padding: 0,
                                        },
                                    }}>
                                    <Typography color={'white'} sx={{ fontSize: { xs: 11, sm: 12, md: 14 } }} style={{ textTransform: 'capitalize' }} >Forms</Typography>
                                </Button> */}
                                <a
                                    href="/termsandconditions" // Replace this with the actual link
                                    style={{
                                        textDecoration: 'none', // Remove underline
                                        display: 'inline-flex', // Keep inline flex layout
                                        alignItems: 'center', // Align text properly
                                        padding: 0,
                                        minWidth: 'auto', // Avoid unnecessary width
                                    }}
                                >
                                    <Typography
                                        color={'white'}
                                        sx={{ fontSize: { xs: 11, sm: 12, md: 14 } }}
                                        style={{
                                            textTransform: 'capitalize',
                                            transition: 'color 0.3s', // Optional: Add smooth color change on hover
                                        }}
                                    >
                                        Terms and Conditions
                                    </Typography>
                                </a>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack                  //right side of bar, ordered as rows
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        sx={{
                            spacing: {
                                xs: 0, // Spacing for extra small screens
                                md: 2, // Spacing for small screens
                            },
                        }}
                    >
                        {/* <IconButton aria-label="facebook" onClick={handleFacebookClick}
                        sx={{
                            padding: 0,
                        }}>
                        <FacebookIcon sx={{ color: 'white' }} />
                    </IconButton> */}
                        <a
                                    href="https://www.linkedin.com/company/captec-usa/" // Replace this with the actual link
                                    target="_blank" // This will trigger the onClick function
                                    style={{
                                        textDecoration: 'none', // Remove underline
                                        display: 'inline-flex', // Keep inline flex layout
                                        alignItems: 'center', // Align text properly
                                        padding: 0,
                                        minWidth: 'auto', // Avoid unnecessary width
                                    }}
                                >
                            <LinkedInIcon sx={{ color: 'white' }} fontSize='large' />
                        </a>
                        {/* <img src={FLabalogo}
                        alt="FL ABA Logo" // Always include alt text for accessibility
                        style={{ maxWidth: '100%', height: '45px' }} // Adjust width and height as needed
                    /> */}
                    </Stack>
                </Stack>
                <Typography color={'white'} sx={{ fontSize: { xs: 11, sm: 12, md: 14 } }} >
                    © {new Date().getFullYear()} CapTec USA. All Rights Reserved.
                    {/* {isXs && <br />} {/* Add line break only on xs screens */}

                </Typography>
            </Stack>
            <Stack                      //most outside layer spaced top to bottom for copyright on bottom with divider
                direction="column"
                //justifyContent="center"
                //alignItems="center"
                spacing={2}
                sx={{ marginLeft: { xs: 1, sm: 2, md: 3 }, marginRight: { xs: 2, sm: 3, md: 4 }, marginTop: 1 }}
            >
                {/* <Typography color={'white'} sx={{ fontSize: { xs: 11, sm: 12, md: 14 } }} >
                    *The New South Bank is currently in its organizational phase and is not yet open for business.
                </Typography> */}
            </Stack>
        </Box>
    );
}