// RemoveTrailingSlash.tsx
import React from "react";
import { Navigate, useLocation } from "react-router-dom";

export default function() {
  const location = useLocation();

  // Check if the URL ends with a trailing slash
  if (location.pathname !== "/" && location.pathname.endsWith("/")) {
    // Remove the trailing slash and redirect
    return (
      <Navigate
        replace
        to={{
          pathname: location.pathname.replace(/\/+$/, ""), // Remove all trailing slashes
          search: location.search, // Preserve the search query parameters
        }}
      />
    );
  }

  return null; // If no trailing slash, nothing is rendered
};


